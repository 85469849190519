import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Text, Center } from '@chakra-ui/react';
import WithAnimation from '@components/Common/WithAnimation';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import Image from '@components/Common/LazyImage';
import ScrollToDown from '@components/Common/ScrollToDown/V1';
import useInvitation from '@hooks/useInvitation';

import { THE_BRIDE } from '@/constants';
import { BG_COVER } from '@/constants/assets';
// import { TEXT_SHADOW } from '@/constants/colors';

import ImgCoverLogo from '@/assets/icons/cover-logo.png';
import { DEFAULT_LANGUAGE } from '@/constants/feature-flags';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isInvitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box minHeight="100vh" backgroundColor="white">
      <Box
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`url(${BG_COVER})`}
      >
        <Box height="100%" bgColor="bgPrimary" opacity={0.3} />
      </Box>
      {showTitle && (
        <Box
          bottom="4rem"
          position="absolute"
          color="secondaryColorText"
          letterSpacing="1px"
          fontWeight="400"
          fontSize="10px"
          width="100%"
          maxW="500px"
          top="calc(100vh - 320px)"
          height="120px"
          padding="0 42px"
        >
          <Center>
            <Image src={ImgCoverLogo} maxW="150px" />
          </Center>
          <Box>
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="3xl"
                color="mainColorText"
                letterSpacing="2px"
                fontFamily="heading"
                fontWeight="bold"
                //textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
              >
                {THE_BRIDE}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="md"
                color="mainColorText"
                textTransform="uppercase"
                letterSpacing="2px"
                //textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
              >
                {isInvitation ? `Wedding Invitation` : `Wedding Announcement`}
              </Text>
            </WithAnimation>
            {/* Just remove the false if you want to enable quotes in cover */}
            {false && (
              <WithAnimation>
                <Text
                  //textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
                  marginTop="16px"
                  textAlign="center"
                  color="mainColorText"
                  fontSize="md"
                >
                  “Happy marriages begin when we marry the one we love, and they blossom when we
                  love the one we married.”
                </Text>
              </WithAnimation>
            )}
            <ScrollToDown
              loaded={loaded}
              loading={loading}
              marginTop="16px"
              text={DEFAULT_LANGUAGE == 'id' ? (isInvitation ? "BUKA UNDANGAN" : "BUKA PEMBERITAHUAN") : (isInvitation ? "LET'S BEGIN" : "OPEN ANNOUNCEMENT")}
              bgColor="bgPrimary"
              color="white"
              onClick={handleClickDetail}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
