// import P1 from '@/images/4/1.jpg';
// import P2 from '@/images/4/2.jpg';
// import P3 from '@/images/4/3.jpg';
// import P4 from '@/images/4/4.jpg';
// import P5 from '@/images/4/5.jpg';
// import P6 from '@/images/4/6.jpg';
// import P7 from '@/images/4/7.jpg';
// import P8 from '@/images/4/8.jpg';
// import P9 from '@/images/4/9.jpg';
// import P10 from '@/images/4/10.jpg';
// import P11 from '@/images/4/11.jpg';
// import P12 from '@/images/4/12.jpg';
// import P13 from '@/images/4/13.jpg';
// import P14 from '@/images/4/14.jpg';
// import P15 from '@/images/4/15.jpg';
// import P16 from '@/images/4/16.jpg';
import P25 from '@/images/2/25.jpg';
import P26 from '@/images/2/26.jpg';
import P27 from '@/images/2/27.jpg';
import P28 from '@/images/2/28.jpg';
import P29 from '@/images/2/29.jpg';
import P30 from '@/images/2/30.jpg';
import P31 from '@/images/2/31.jpg';
import P32 from '@/images/2/32.jpg';
import P33 from '@/images/2/33.jpg';
import P34 from '@/images/2/34.jpg';

export const photos = [
  // {
  //   original: P1,
  //   // thumbnail: P1,
  // },
  // {
  //   original: P2,
  //   // thumbnail: P2,
  // },
  // {
  //   original: P3,
  //   // thumbnail: P3,
  // },
  // {
  //   original: P4,
  //   // thumbnail: P4,
  // },
  // {
  //   original: P5,
  //   // thumbnail: P5,
  // },
  // {
  //   original: P6,
  //   // thumbnail: P6,
  // },
  // {
  //   original: P7,
  //   // thumbnail: P7,
  // },
  // {
  //   original: P8,
  //   // thumbnail: P8,
  // },
  // {
  //   original: P9,
  //   // thumbnail: P9,
  // },
  // {
  //   original: P10,
  //   // thumbnail: P10,
  // },
  // {
  //   original: P11,
  //   // thumbnail: P11,
  // },
  // {
  //   original: P12,
  //   // thumbnail: P12,
  // },
  // {
  //   original: P13,
  //   // thumbnail: P13,
  // },
  // {
  //   original: P14,
  //   // thumbnail: P14,
  // },
  // {
  //   original: P15,
  //   // thumbnail: P15,
  // },
  // {
  //   original: P16,
  //   // thumbnail: P16,
  // },
  // {
  //   original: P17,
  //   // thumbnail: P17,
  // },
  // {
  //   original: P18,
  //   // thumbnail: P18,
  // },
  // {
  //   original: P19,
  //   // thumbnail: P19,
  // },
  // {
  //   original: P20,
  //   // thumbnail: P20,
  // },
  // {
  //   original: P21,
  //   // thumbnail: P21,
  // },
  // // {
  // //   original: P22,
  // //   // thumbnail: P22,
  // // },
  // {
  //   original: P23,
  //   // thumbnail: P23,
  // },
  // {
  //   original: P24,
  //   // thumbnail: P24,
  // },
  {
    original: P25,
    // thumbnail: P25,
  },
  {
    original: P26,
    // thumbnail: P26,
  },
  {
    original: P27,
    // thumbnail: P27,
  },
  {
    original: P28,
    // thumbnail: P28,
  },
  {
    original: P29,
    // thumbnail: P29,
  },
  {
    original: P30,
    // thumbnail: P30,
  },
  {
    original: P31,
    // thumbnail: P31,
  },
  {
    original: P32,
    // thumbnail: P32,
  },
  {
    original: P33,
    // thumbnail: P33,
  },
  {
    original: P34,
    // thumbnail: P34,
  },
];
