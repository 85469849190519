import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import BgBorder from '@/assets/border/wedding.png';
import ImgDivider from '@/assets/border/divider.png';

import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import Image from '@components/Common/LazyImage';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import useLang from '@hooks/useLang';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { IMG_LOGO_WEDDING } from '@/constants/assets';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const shiftTime = useShiftTime();
  const isInvitation = useInvitation();
  const defaultLang = useLang();
  const lang = defaultLang;

  /** Render Wedding Information */
  const renderWeddingInfo = ({ title, desc, date, time, place, placeRoad }) => {
    return (
      <WithAnimation>
        <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
          <Text
            fontWeight="bold"
            fontSize="xl"
            margin="24px 0"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text m="16px 0">{desc}</Text>
          <Text fontWeight="bold">{date}</Text>
          <Text fontWeight="bold">{time}</Text>
          {place && <Text fontWeight="bold" marginTop="12px" dangerouslySetInnerHTML={{ __html: place }} />}
          {placeRoad && <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: placeRoad }} />}
        </Box>
      </WithAnimation>
    );
  };

  return (
    <Box bgColor="bgPrimary" padding="32px">
      <Box
        {...rest}
        padding="42px 24px"
        bgRepeat="no-repeat"
        width="100%"
        bgSize="100% 100%"
        bgImage={`url(${BgBorder})`}
      >
        <Box>
          <WithAnimation>
            <Center margin="-18px 0 -16px">
              <Image src={IMG_LOGO_WEDDING} maxWidth="220px" />
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Box
              marginTop="8px"
              fontFamily="body"
              textAlign="center"
              color="mainColorText"
            >
              <Text fontSize="md" >
                {`For the two of us, home isn't a place. It is a person. And we are finally home.`}
              </Text>
              <Text fontSize="md">- Stephanie Perkins -</Text>
            </Box>
          </WithAnimation>
          {/* Box Reception Information */}
          <WithAnimation>
            <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
              {MUSLIM_INVITATION && !isInvitation && 
                <> 
                  {renderWeddingInfo ({
                    title: txtWording.akadMuslim[lang],
                    desc: txtWording.descAkadMuslim[lang],
                    date: WEDDING_AKAD_FULLDATE,
                    time: WEDDING_AKAD_TIME,
                    place: false && WEDDING_AKAD_LOC_NAME,
                    placeRoad: false && WEDDING_AKAD_LOC_ROAD,
                  })} 
                </>
              }
              
              {!MUSLIM_INVITATION && <> {renderWeddingInfo ({
                title: txtWording.akadGeneral[lang],
                desc: isInvitation
                  ? txtWording.descAkadInvitationGeneral[lang]
                  : txtWording.descAkadGeneral[lang],
                date: WEDDING_AKAD_FULLDATE,
                time: isInvitation ? WEDDING_AKAD_TIME : '',
                place: WEDDING_AKAD_LOC_NAME,
                placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
              })} </>}
              
              {isInvitation && false && (
                <WithAnimation>
                  <Center>
                    <Image src={ImgDivider} maxWidth="100%" marginTop="24px" />
                  </Center>
                </WithAnimation>
              )}
              
              {/* Wedding - info */}
              {isInvitation &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: txtWording.descReception[lang],
                  date: WEDDING_RESEPSI_FULLDATE,
                  time: shiftTime,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                })
              }
            </Box>
          </WithAnimation>
          
          {/* BOX Button View Location */}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  marginTop="16px"
                  size="sm"
                  fontWeight="normal"
                  bgColor="bgSecondary"
                  color="btnMainColor"
                  text={txtWording.location[lang]}
                  {...DEFAULT_BUTTON_PROPS}
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);