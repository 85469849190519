import React from 'react';
import { string } from 'prop-types';
import { Button, Link } from '@chakra-ui/react';

import { GOOGLE_MAPS_LINK } from '@/constants';

function ViewLocationButton({ text, href, ...rest }) {
  return (
    <Link href={href ? href : GOOGLE_MAPS_LINK} target="_blank">
      <Button _focus={{ borderColor: 'transparent' }} {...rest}>
        {text}
      </Button>
    </Link>
  );
}

ViewLocationButton.propTypes = {
  href: string,
  text: string.isRequired,
};

ViewLocationButton.defaultProps = {
  href: '',
  text: string.isRequired,
};

export default ViewLocationButton;
