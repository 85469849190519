import React from 'react';
import { Box, Heading, Text, Image, Center } from '@chakra-ui/react';

import useGuestInfo from '@/hooks/useGuestInfo';
import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@components/Common/WithAnimation';

import { IS_BOY_FIRST, BOY_NAME_SHORT, GIRL_NAME_SHORT } from '@/constants';
import { IMG_LAMPION } from '@/constants/assets';

import useLang from '@hooks/useLang';

import txtWording from './locales';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';

function WelcomeV4() {
  const defaultLang = useLang();
  const lang = defaultLang;

  const { name } = useGuestInfo();
  const isInvitation = useInvitation();

  return (
    <Box padding="0 32px 60px 32px" bgColor="bgPrimary">
      <WithAnimation>
        <Center>
          <Image src={IMG_LAMPION} maxW="230px" margin="-2px 0 32px 0" />
        </Center>
      </WithAnimation>
        <WithAnimation>
          <Box marginBottom="52px">
            <Text
              textAlign="center"
              color="mainColorText"
              fontFamily="body"
              fontSize="md"
              margin="0"
            >
              {txtWording.textDear[lang]}
            </Text>
            <Text marginTop="4px" fontSize="xl" color="mainColorText" textAlign="center">
            {name ? name : txtWording.guest[lang]}
            </Text>
          </Box>
        </WithAnimation>
      <WithAnimation>
        <Box margin="24px 0" textColor="mainColorText" letterSpacing="4px" textAlign="right">
          <Heading fontSize="4xl">
            {
              IS_BOY_FIRST 
              ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
              : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`
            }
          </Heading>
        </Box>
      </WithAnimation>
      {/* Greetings Text */}
      <Box>
        <WithAnimation>
          <Text color="mainColorText" textAlign="right" fontSize="md">
          {MUSLIM_INVITATION &&
              <>
                Assalamualaikum Warahmatullahi Wabarakatuh.
                <br />
              </>
            }
            {isInvitation
              ? <>{MUSLIM_INVITATION ? txtWording.welcomingInvitationMuslim[lang] : txtWording.welcomingInvitation[lang]}</>
              : <>{txtWording.welcomingAnnouncement[lang]}</>
            }
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);
