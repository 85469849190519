import React from 'react';
import { Box, Center, Text, Link, IconButton, Divider } from '@chakra-ui/react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

import Image from '@components/Common/LazyImage';
import WithAnimation from '@components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';
import { ENABLE_COLLABORATION, MUSLIM_INVITATION, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';
import txtWording from './locales';
import useLang from '@hooks/useLang';

import ImgDivider from '@/assets/border/divider.png';
import IconFooter from './IconFooter';

import {
  THE_BRIDE,
  SOUND_BY,
  SOUND_URL,
  URL_WA_INVITATO,
  URL_IG_INVITATO,
  URL_INVITATO,
} from '@/constants';
import { BG_FOOTER, LOGO_INVITATO } from '@/constants/assets';
import { BG_PRIMARY, BG_PRIMARY_GRADIENT } from '@/constants/colors';

const FOOTER_LINK_PROPS = {
  color: 'mainColorText',
  isExternal: true,
  textAlign: 'center',
  fontSize: 'xs',
};

function FooterSection() {
  const defaultLang = useLang();
  const lang = defaultLang;
  const isInvitation = useInvitation();

  return (
    <Box bgSize="cover" minHeight="100vh" bgPos="bottom center">
      <Image src={BG_FOOTER} width="100%" />
      <Center>
        <Box position="absolute" zIndex={2} transform="translateY(-4px)">
          <IconFooter />
        </Box>
      </Center>
      {/* Title and Desc Section */}
      <Box
        backgroundImage="bgPrimary"
        textAlign="center"
        color="secondaryColorText"
        fontWeight="400"
        width="100%"
        maxW="500px"
      >
        <Box
          backgroundImage={`linear-gradient(180deg, ${BG_PRIMARY}, ${BG_PRIMARY_GRADIENT} 300%)`}
          padding="60px 24px 24px 24px"
        >
          {/* Logo Section */}
          <WithAnimation>
            <Text fontSize="md" textTransform="uppercase" color="mainColorText" dangerouslySetInnerHTML={{ __html: txtWording.thanks[lang] }} />
          </WithAnimation>
          <WithAnimation>
            <Text margin="18px 0 0px" fontSize="md" fontFamily="body" color="mainColorText">
              {isInvitation
                ? txtWording.closingInvitation[lang]
                : txtWording.closingAnnouncement[lang]
              }
            </Text>          
          </WithAnimation>
          {MUSLIM_INVITATION &&<WithAnimation>
          <Text fontSize="md" fontFamily="body" color="mainColorText">
              Wassalamualaikum Warahmatullahi Wabaraktuh
            </Text>
          </WithAnimation>}
          <WithAnimation>
            <Text marginTop="18px" fontSize="3xl" fontFamily="heading" color="mainColorText">
              {`- ${THE_BRIDE} -`}
            </Text>
          </WithAnimation>

          <WithAnimation>
            <Center>
              <Image src={ImgDivider} maxWidth="60%" margin="42px 0" />
            </Center>
          </WithAnimation>

          {/* INVITATO SECTION */}
          <Box>
          
            <Center>
              <Link href={URL_INVITATO} target="_blank">
                <Image src={LOGO_INVITATO} maxWidth="110px" margin="0 0 8px 0" />
              </Link>
            </Center>
            <Text color="mainColorText" fontFamily="body" textAlign="center" fontSize="xs">
              Created with Love by Invitato
            </Text>
            {false &&<WithAnimation>
              <Center marginBottom="16px">
                <Link href={URL_IG_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    background="#9f6b44"
                    border="none"
                    icon={<FaInstagram color="white" size="20px" />}
                    marginRight="4px"
                  />
                </Link>
                <Link href={URL_WA_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    icon={<FaWhatsapp color="white" size="20px" />}
                    marginLeft="4px"
                    background="#9f6b44"
                    border="none"
                  />
                </Link>
              </Center>
            </WithAnimation>}
            {ENABLE_COLLABORATION &&
            <Box margin="-4px 0 18px">
              <Center>
                <Divider
                  margin="16px 0"
                  maxWidth="130px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                />
              </Center>
              <Center>
                  <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                  <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                  </Link>
                </Center>
                <Center>
                  <Text color="mainColorText" align="center" fontSize="xs">
                  In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                  </Text>
                </Center>
            </Box>
            }
            <Text {...FOOTER_LINK_PROPS}>{`© 2023 ${THE_BRIDE}. All Right Reserved`}</Text>
            <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
              <Text>{`Song by ${SOUND_BY}`}</Text>
            </Link>
            <Link
              {...FOOTER_LINK_PROPS}
              href="https://www.pinterest.com/pin/662029213981587631/sent/?invite_code=ce908ca32d6349398315802b25349cd0&sender=662029351381980030&sfo=1"
            >
              <Text>Peony Floral Pattern by Pinterest</Text>
            </Link>
            <Link
              {...FOOTER_LINK_PROPS}
              href="https://www.cmog.org/photo/luminaire-bronze-bois-fer"
            >
              <Text> Luminaire: Bronze, Bois, Fer by Cmog </Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
